var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"position-relative pb-1"},[(_vm.loading)?_c('div',{staticClass:"loader-overlay"},[_c('b-spinner',{staticStyle:{"width":"4rem","height":"4rem"},attrs:{"variant":"primary"}})],1):_vm._e(),_c('b-card',[_c('form',{ref:"Form",staticClass:"mb-3",attrs:{"color":"#7367F0","title":null,"subtitle":null,"shape":"square","finish-button-text":"Submit","back-button-text":"Previous"},on:{"on-complete":_vm.submit}},[_c('validation-observer',{ref:"FormInfo",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Products","label-for":"Products"}},[_c('validation-provider',{attrs:{"name":"Products","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"custom-select-style",class:errors.length > 0 ? 'border-danger-for-v-select' : '',attrs:{"id":"Products","options":_vm.ProductsOptions,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"placeholder":"Select Product","reduce":function (product) { return product.id; },"label":"name"},on:{"input":_vm.selectProduct},model:{value:(_vm.value.category_id),callback:function ($$v) {_vm.$set(_vm.value, "category_id", $$v)},expression:"value.category_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Item Name","label-for":"Item Name"}},[_c('validation-provider',{attrs:{"name":"Item Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-bootstrap-typeahead',{ref:"itemInput",class:_vm.item_name_validation? 'border-danger' : '',attrs:{"data":_vm.itemNameOptions,"serializer":function (el) { return el.name; },"placeholder":"Type an Item Name..."},on:{"hit":function($event){_vm.value.item_id = $event.id}}}),_c('small',{directives:[{name:"show",rawName:"v-show",value:(_vm.item_name_validation),expression:"item_name_validation"}],staticClass:"text-danger"},[_vm._v("Item Name field is required")])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Item Code","label-for":"Item Code"}},[_c('validation-provider',{attrs:{"name":"Item Code","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"custom-select-style",class:errors.length > 0 ? 'border-danger-for-v-select' : '',attrs:{"id":"Item Code","options":_vm.itemCodeOptions,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"placeholder":"Select Item Code","reduce":function (code) { return code.id; },"label":"name"},model:{value:(_vm.value.reference_code_id),callback:function ($$v) {_vm.$set(_vm.value, "reference_code_id", $$v)},expression:"value.reference_code_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Arabic Item Name","label-for":"arabic_name"}},[_c('validation-provider',{attrs:{"name":"arabic_name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Arabic Item Name"},model:{value:(_vm.value.arabic_name),callback:function ($$v) {_vm.$set(_vm.value, "arabic_name", $$v)},expression:"value.arabic_name"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Description","label-for":"description"}},[_c('validation-provider',{attrs:{"name":"description"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-bootstrap-typeahead',{ref:"descriptionInput",class:_vm.description_validation ? 'border-danger': '',attrs:{"data":_vm.descriptionOptions,"serializer":function (el) { return el.content; },"placeholder":"Type an Description..."},on:{"hit":function($event){_vm.value.description_id = $event.id}}}),_c('small',{directives:[{name:"show",rawName:"v-show",value:(_vm.description_validation),expression:"description_validation"}],staticClass:"text-danger"},[_vm._v("Description field is required")])]}}])})],1)],1),_c('b-col',{attrs:{"md":"2"}},[_c('b-form-group',{attrs:{"label":"Additional Option ?","label-for":"additional_option"}},[_c('validation-provider',{attrs:{"name":"additional_option","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"switch":"","inline":""},model:{value:(_vm.value.additional_option),callback:function ($$v) {_vm.$set(_vm.value, "additional_option", $$v)},expression:"value.additional_option"}},[_vm._v(" "+_vm._s(_vm.value.additional_option ? "YES" : "NO")+" ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"2"}},[_c('b-form-group',{attrs:{"label":"Support Glass Details ?","label-for":"with_glass_details"}},[_c('validation-provider',{attrs:{"name":"with_glass_details"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"switch":"","inline":""},model:{value:(_vm.value.with_glass_details),callback:function ($$v) {_vm.$set(_vm.value, "with_glass_details", $$v)},expression:"value.with_glass_details"}},[_vm._v(" "+_vm._s(_vm.value.with_glass_details ? "YES" : "NO")+" ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Low Price","label-for":"low_price"}},[_c('validation-provider',{attrs:{"name":"low_price","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{attrs:{"type":"number","state":errors.length > 0 ? false : null,"placeholder":"Low Price"},model:{value:(_vm.value.low_price),callback:function ($$v) {_vm.$set(_vm.value, "low_price", $$v)},expression:"value.low_price"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_vm._v("AED")])],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"High Price","label-for":"high_price"}},[_c('validation-provider',{attrs:{"name":"high_price","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{attrs:{"type":"number","state":errors.length > 0 ? false : null,"placeholder":"High Price"},model:{value:(_vm.value.high_price),callback:function ($$v) {_vm.$set(_vm.value, "high_price", $$v)},expression:"value.high_price"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_vm._v("AED")])],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_c('b-button',{staticClass:"float-right",attrs:{"id":"show-btn","variant":"primary"},on:{"click":_vm.submit}},[_vm._v(_vm._s(_vm.action))])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }