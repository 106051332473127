<template>
  <div class="">
    <item-form ref="form" action="Save" v-model="formData" @formSubmitted="storeItem"/>
  </div>
</template>

<script>
import ItemForm from "@/views/components/item/ItemForm.vue";
import ItemDescriptionService from "@/services/ItemDescriptionService";

export default {
  name: "CreateOffer",
  components: {
    ItemForm
  },
  data() {
    return {
      ItemDescriptionService: new ItemDescriptionService(),
      formData: {
        additional_option: false,
        with_glass_details: true
      },
    }
  },
  methods: {
    storeItem() {
      this.$refs.form.loading = true;
      this.ItemDescriptionService.create(this.formData).then(res => {
        this.$router.push({name: 'all-items'})
      }).catch(err => {
      }).finally(() => {
        this.$refs.form.loading = false;
      })
    },
  }
}
</script>

<style scoped>

</style>
