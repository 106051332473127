import CrudService from "@/services/shared/http/CrudService";

export default class ItemDescriptionService extends CrudService {
  constructor(params = {}) {
    super("item-description", params);
  }

  changeStatus(id, value) {
    return this.api.PATCH(`item-active-inactive/${id}`, {
      status: value,
    });
  }
}
