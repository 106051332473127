<template>
  <div class="position-relative pb-1">
    <div v-if="loading" class="loader-overlay">
      <b-spinner style="width: 4rem; height: 4rem;" variant="primary" />
    </div>
    <b-card>
      <form ref="Form" color="#7367F0" :title="null" :subtitle="null" shape="square" finish-button-text="Submit"
        back-button-text="Previous" class="mb-3" @on-complete="submit">
        <validation-observer ref="FormInfo" tag="form">
          <b-row>
            <b-col md="4">
              <b-form-group label="Products" label-for="Products">
                <validation-provider #default="{ errors }" name="Products" rules="required">
                  <v-select id="Products" class="custom-select-style" v-model="value.category_id" @input="selectProduct"
                    :class="errors.length > 0 ? 'border-danger-for-v-select' : ''" :options="ProductsOptions"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" placeholder="Select Product"
                    :reduce="product => product.id" label="name" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group label="Item Name" label-for="Item Name">
                <validation-provider #default="{ errors }" name="Item Name">
                  <vue-bootstrap-typeahead :data="itemNameOptions" :serializer="el => el.name"
                    :class="item_name_validation? 'border-danger' : ''" placeholder="Type an Item Name..."
                    @hit="value.item_id = $event.id" ref="itemInput" />
                  <small v-show="item_name_validation" class="text-danger">Item Name field is required</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="Item Code" label-for="Item Code">
                <validation-provider #default="{ errors }" name="Item Code" rules="required">
                  <v-select id="Item Code" class="custom-select-style" v-model="value.reference_code_id"
                            :class="errors.length > 0 ? 'border-danger-for-v-select' : ''" :options="itemCodeOptions"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" placeholder="Select Item Code"
                            :reduce="code => code.id" label="name" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group label="Arabic Item Name" label-for="arabic_name">
                <validation-provider #default="{ errors }" name="arabic_name" rules="required">
                  <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                    <b-form-input v-model="value.arabic_name" :state="errors.length > 0 ? false : null"
                                  placeholder="Arabic Item Name" />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group label="Description" label-for="description">
                <validation-provider #default="{ errors }" name="description">
                  <vue-bootstrap-typeahead :data="descriptionOptions" :serializer="el => el.content"
                    placeholder="Type an Description..." @hit="value.description_id = $event.id"
                    :class="description_validation ? 'border-danger': ''" ref="descriptionInput" />
                  <small v-show="description_validation" class="text-danger">Description field is required</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="2">
              <b-form-group label="Additional Option ?" label-for="additional_option">
                <validation-provider #default="{ errors }" name="additional_option" rules="required">
                  <b-form-checkbox switch inline v-model="value.additional_option">
                    {{value.additional_option ? "YES" : "NO"}}
                  </b-form-checkbox>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="2">
              <b-form-group label="Support Glass Details ?" label-for="with_glass_details">
                <validation-provider #default="{ errors }" name="with_glass_details" >
                  <b-form-checkbox switch inline v-model="value.with_glass_details">
                    {{value.with_glass_details ? "YES" : "NO"}}
                  </b-form-checkbox>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group label="Low Price" label-for="low_price">
                <validation-provider #default="{ errors }" name="low_price" rules="required">
                  <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                    <b-form-input v-model="value.low_price" type="number" :state="errors.length > 0 ? false : null"
                      placeholder="Low Price" />
                    <b-input-group-append is-text>AED</b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group label="High Price" label-for="high_price">
                <validation-provider #default="{ errors }" name="high_price" rules="required">
                  <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                    <b-form-input v-model="value.high_price" type="number" :state="errors.length > 0 ? false : null"
                      placeholder="High Price" />
                    <b-input-group-append is-text>AED</b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
        <b-button id="show-btn" @click="submit" class="float-right" variant="primary">{{action}}</b-button>
      </form>
    </b-card>

  </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import DatePicker from 'vue2-datepicker'
import Cleave from 'vue-cleave-component'

import {
  BFormGroup,
  BFormInput, BCard,
  BRow, BFormTextarea,
  BFormInvalidFeedback,
  BCol, BInputGroupAppend,
  BInputGroup, BFormCheckbox,
  BSpinner, BFormDatepicker, BButton
} from 'bootstrap-vue'
import { required } from '@validations'
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import ProductService from "@/services/ProductService";
import DescriptionService from "@/services/DescriptionService";
import ApiService from "@/services/shared/http/ApiService";

export default {
  components: {
    BFormDatepicker,
    ValidationProvider,
    ValidationObserver,
    FormWizard, BButton,
    BRow, BCol, BFormGroup,
    BInputGroup, BFormInput,
    TabContent, BFormTextarea,
    DatePicker, Cleave,
    vSelect, BFormInvalidFeedback,
    BSpinner, BCard, BInputGroupAppend,
    BFormCheckbox, VuePhoneNumberInput,
  },
  props: ['value', 'action'],
  data() {
    return {
      ProductService: new ProductService(),
      ApiService: new ApiService(),
      DescriptionService: new DescriptionService(),
      phone_number: undefined,
      whatsapp_number: undefined,
      whatsapp_validate: true,
      phone_validate: true,
      isSameNumber: true,
      loading: false,
      item_name_validation: false,
      description_validation: false,
      required,
      ProductsOptions: [],
      itemNameOptions: [],
      descriptionOptions: [],
      itemCodeOptions: [],
      arabic_item_name_validation: false
    }
  },
  watch: {
    "value.email": function (newValue) {
      this.value.email = newValue.replaceAll("@", "");
    },
    "value.with_glass_details": function (newValue) {
      this.value.with_glass_details = this.value.with_glass_details == 1
    },
    "value.arabic_name": function (newValue) {
      this.value.arabic_name = newValue.replace(/[^\u0600-\u06FF-\s\_]/g, "");
    },
  },
  methods: {
    submit() {
      if (!this.itemNameOptions.some(el => el.name == this.$refs.itemInput.inputValue)) {
        this.value.item = this.$refs.itemInput.inputValue;
        delete this.value.item_id;
      }
      if (!!!this.$refs.itemInput.inputValue && !!!this.value.item_id) {
        this.item_name_validation = true
      } else {
        this.item_name_validation = false
      }
      if (!!!this.$refs.descriptionInput.inputValue && !!!this.value.description_id) {
        this.description_validation = true
      } else {
        this.description_validation = false
      }
      // if (!!!this.$refs.arabicItemInput.inputValue && !!!this.value.item_id) {
      //   this.arabic_item_name_validation = true
      // } else {
      //   this.arabic_item_name_validation = false
      // }
      if (!this.descriptionOptions.some(el => el.content == this.$refs.descriptionInput.inputValue)) {
        this.value.description = this.$refs.descriptionInput.inputValue;
        delete this.value.description_id;
      }
      this.$refs.FormInfo.validate().then(success => {
        if (success) {
          if (!this.item_name_validation && !this.description_validation) {
            this.$emit('formSubmitted')
          }
        } else {
        }
      })
    },
    getDescription() {
      this.loading = true;
      this.DescriptionService.index().then(res => {
        this.descriptionOptions = res.data;
      }).catch(err => { })
        .finally(() => {
          this.loading = false;
        });
    },
    getItemCode() {
      this.ApiService.GET('item-reference-code', {per_page: 200}).then((res) => {
        this.itemCodeOptions = res.data;
      });
    },
    getProduct() {
      this.loading = true;
      this.ProductService.index().then(res => {
        this.ProductsOptions = res.data;
      }).catch(err => { })
        .finally(() => {
          if (this.value.category_id){
            this.selectProduct(this.value.category_id);
          }
          this.loading = false;
        });
    },
    selectProduct(value) {
      value = this.ProductsOptions.find(el => el.id == value);
      this.itemNameOptions = value.items;
      this.value.category = value.id;
    },
    set_phone_number(event) {
      this.$emit('input', { ...this.value, phone_number: event.formattedNumber });
      this.phone_validate = event.isValid;
    },
    set_whatsapp_number(event) {
      this.$emit('input', { ...this.value, whatsapp_number: event.formattedNumber });
      this.whatsapp_validate = event.isValid;
    },
    test(value) {
      console.log(value);
    }
  },
  async mounted() {
    await this.getDescription();
    await this.getProduct();
    await this.getItemCode();
  }
}
</script>
<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';

.input-tel.is-focused .input-tel__input {
  box-shadow: unset !important;
}

.loader-overlay {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: wait;
}

.loader-overlay~div {
  filter: blur(3px);
}

.z-index-0 {
  z-index: 0;
}

@media (max-width: 576px) {
  .wizard-nav.wizard-nav-pills {
    position: sticky;
    top: 70px;
    z-index: 3;
    background: #fff;
  }

  .vue-form-wizard .wizard-navigation .wizard-nav li {
    padding-bottom: 8px;
  }
}

::v-deep .dropdown-menu {
  z-index: 12;
}
</style>
